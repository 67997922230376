import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {
    IAvisMotiveFormData,
    IInspectionDocumentsFormData,
    InspectionsService
} from "src/Services/Inspections.service";
import UseInspection from "src/Hooks/UseInspection";
import {IInspection} from "src/Models/Inspection.model";
import AsideInspectionsComponent from "src/Components/Aside/AsideInspections.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import TableauBordDocuments from "src/Components/TableauxBord/TableauBordDocuments.component";
import {ITableauBord} from "src/Models/TableauBord.model";
import AsideTableauxBordComponent from "src/Components/Aside/AsideTableauxBord.component";
import {IStatistiques} from "src/Models/Statistiques.model";
import OfficesHelper from "src/Helpers/Offices.helper";
import StatistiquesTable from "src/Components/Inspections/Statistiques/StatistiquesTable.component";
import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import TableauBordOfficeComponent from "src/Components/TableauxBord/TableauBordOffice.component";
import AvisMotiveInfosComponent from "src/Components/Inspections/Inspections/AvisMotiveInfos.component";
import AvisMotiveFormComponent from "src/Components/Forms/Inspections/Inspections/AvisMotiveForm.component";
import {IAvisMotive} from "src/Models/AvisMotive.model";
import InspectionCourrierFormForm from "src/Components/Forms/Inspections/Inspections/InspectionCourrierForm.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import InspectionHistoriquesInspecteurListComponent
    from "src/Components/Inspections/Inspections/InspectionHistoriquesInspecteurList.component";
import InspectionDocumentsComponent from "src/Components/Inspections/Inspections/InspectionDocuments.component";
import InspectionDocumentsFormComponent
    from "src/Components/Forms/Inspections/Inspections/InspectionDocumentsForm.component";


type IInspectionFicheScreenScreenProps = {
    id: string
}
export default function InspectionFicheScreen(props: IInspectionFicheScreenScreenProps) {
    const tableauBordAccordionRef = useRef(null);
    const avisMotiveAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);

    const inspectionsService: InspectionsService = new InspectionsService();

    const {getInspectionById} = UseInspection();
    const [inspection, setInspection] = useState<IInspection>(null);
    const [tableauBord, setTableauBord] = useState<ITableauBord>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [avisMotiveList, setAvisMotiveList] = useState<IAvisMotive[]>(null);
    const [stats, setStats] = useState<IStatistiques[]>(null);
    const [lastYearStats, setLastYearStats] = useState<IStatistiques>(null);
    const [firstYear, setFirstYear] = useState<number>(0);
    const [allInspections, setAllInspesction] = useState<IInspection[]>(null);
    const [anneesInspections, setAnneesInspections] = useState<number[]>([]);
    const [isEditingHistorique, setIsEditingHistorique] = useState<boolean>(false);

    useEffect(() => {
        if (!inspection) return;

        const breadcrumbLinks = [
            {text: "Inspections", link: AppConstants.pathMosaique + '#inspections'},
            {text: "Inspections", link: AppConstants.pathInspections},
            {text: inspection.office.nom},
        ];
        setBreadcrumbLinks(breadcrumbLinks);


    }, [inspection, loaded]);


    useEffect(() => {
        if (!props.id) {
            history.push(AppConstants.pathInspections);
        }
        getInspection();

    }, [props.id])


    /**
     * Récupération d'une inspection selon l'id
     */
    const getInspection = (): void => {
        getInspectionById(props.id, (inspection: IInspection, tableauBord: ITableauBord, stats: IStatistiques[], avisList: IAvisMotive[], allInspections: IInspection[]) => {
            setInspection(inspection);
            setTableauBord(tableauBord);

            const officeStats = stats;
            setStats(officeStats);
            if (officeStats && officeStats.length) {
                const lastYear = officeStats[officeStats.length - 1];
                setLastYearStats(lastYear);
                setFirstYear(lastYear.dapYear as number - 5);
            }

            setAvisMotiveList(avisList);

            //Récupérer les années des inspections
            let annees = allInspections.map((i: IInspection) => i.annee);

            if (annees.length > 0) {
                //Trier du plus petit au plus grand (pour ajouter les années manquantes)
                annees.sort((a, b) => a - b);

                //Ajouter les années manquantes (= année sans inspection)
                //Note: L'API renvoie uniquement les inspections existantes
                for (let i = annees[0]; i < annees[annees.length - 1]; i++) {
                    if (!annees.includes(i)) {
                        annees.push(i);
                    }
                }

                //Trier du plus grand au plus petit
                annees.sort((a, b) => b - a);
            }
            setAnneesInspections(annees);

            setAllInspesction(allInspections);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getInspection();

        closeForms();
    };

    /**
     * Callback au succès du submit de l'envoi de courrier
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitCourrierForm = (response: IApiCustomResponse): void => {
        getInspection();
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if ((tableauBordAccordionRef && tableauBordAccordionRef.current)) {
            tableauBordAccordionRef.current.hideForm(isCancel);
        }
        if ((avisMotiveAccordionRef && avisMotiveAccordionRef.current)) {
            avisMotiveAccordionRef.current.hideForm(isCancel);
        }
        if ((documentsAccordionRef && documentsAccordionRef.current)) {
            documentsAccordionRef.current.hideForm(isCancel);
        }
    };


    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent
                        breadcrumbLinks={breadcrumbLinks}
                        text={`Inspections`}
                        subText={inspection.office.nom}
                        icon="icon-inspections"
                        modificators=""
                        onSearch={null}
                    />


                    <TabsContainerComponent>
                        <TabPanelComponent label="Historique" icon="icon-historique">
                            <div className="fiche-content -right-gutter border-footer">

                                <table className={`list-table`}>
                                    <thead className={"headers"}>
                                    <tr>
                                        {[
                                            {slug: "etat", label: "Etat"},
                                            {slug: "annee", label: "Année"},
                                            {slug: "inspecteurs", label: "Inspecteurs"},
                                        ].map((item, iIndex) => <th className="th" key={iIndex}>
                                            <TableHeaderButtonComponent
                                                direction={AppConstants.orderType.asc}
                                                value={item.slug} selectedValue={""}
                                                label={item.label} click={null}/>
                                        </th>)}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        //Parcourir les années des inspections (dont les années sans inspection)
                                        (allInspections && anneesInspections.length > 0) && anneesInspections.map((annee: number, index: number) => {
                                            //Récupérer l'inspection de l'année (si elle existe)
                                            const insp: IInspection = allInspections.find((i: IInspection) => i.annee === annee);
                                            let etat: string = '';
                                            if (insp != null && insp.etat != null) {
                                                etat = insp.etat;
                                            }
                                            return (
                                                <tr key={index} className="tr -no-hover">
                                                    <td className="td -bullet -w100px">
                                                        <span className={`state-bullet icon-bullet -${etat}`}/>
                                                    </td>
                                                    <td className="td -bold -w200px">
                                                        Année {annee}
                                                    </td>
                                                    <td className="td -multiline">
                                                        {
                                                            //Afficher la partie inspecteur si l'inspection existe
                                                            insp != null ?
                                                                <InspectionHistoriquesInspecteurListComponent
                                                                    onSuccessSubmitForm={onSuccessSubmitForm}
                                                                    inspecteurs={insp.inspecteurs}
                                                                    inspectionId={insp.id}
                                                                    annee={annee}
                                                                    index={index}
                                                                    pageEditingStatus={isEditingHistorique}
                                                                    setPageEditingStatus={setIsEditingHistorique}
                                                                /> : <strong>Pas d'inspection en {annee} pour cet office</strong>
                                                        }
                                                    </td>
                                                </tr>)
                                        })
                                    }
                                    </tbody>
                                </table>


                                <AsideInspectionsComponent type="fiche" inspection={inspection}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Statistiques" icon="icon-statistiques">
                            <div className="fiche-content -right-gutter border-footer">

                                {
                                    lastYearStats && lastYearStats.data && lastYearStats.data.dap &&
                                    <div className="statistiques-office">
                                        <div className="item">
                                            <span className="title">Nombres de notaires : </span>
                                            <span className="value">{lastYearStats.nombreNotaires}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">Nombres de salariés : </span>
                                            <span className="value">{lastYearStats.nombreSalaries}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">Type d’office : </span>
                                            <span
                                                className="value">{OfficesHelper.getFormatedType(inspection.office.type)}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">Equiv. Temps pleins : </span>
                                            <span
                                                className="value">{lastYearStats.data.dap.equivalent_temps_plein_en_fin_dexercice || 0}</span>
                                        </div>
                                    </div>
                                }


                                <StatistiquesTable office={inspection.office} firstYear={firstYear} onSubmit={null}
                                                   onEditModeChange={null} stats={stats} editMode={false}
                                                   onValueChange={null}/>

                                <AsideInspectionsComponent type="fiche" inspection={inspection}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Tableau de bord" icon="icon-tableau-bord">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionSimpleComponent title="Office" icon="icon-office" contentShowedOnInit={false}>
                                    <TableauBordOfficeComponent office={tableauBord.office} tableauBord={tableauBord}/>
                                </AccordionSimpleComponent>

                                <AccordionSimpleComponent title="Documents" icon="icon-documents"
                                                          contentShowedOnInit={true}>
                                    <TableauBordDocuments documents={tableauBord.documents}/>
                                </AccordionSimpleComponent>

                                <AsideTableauxBordComponent type="fiche" tableauBord={tableauBord}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Saisie avis motivé" icon="icon-saisie-avis-motive">
                            <div className="fiche-content -right-gutter border-footer">

                                {
                                    avisMotiveList && avisMotiveList.map((avis, index) =>
                                        <AccordionWithFormSwitchComponent
                                            key={index}
                                            ref={avisMotiveAccordionRef}
                                            title={`Avis Motivé - ${avis.annee}`}
                                            icon="icon-saisie-avis-motive"
                                            contentShowedOnInit={true}
                                            skipProtectionCheck={true}
                                            contentType="inspection"
                                            contentId={inspection.id}
                                            form={<AvisMotiveFormComponent avis={avis}
                                                                           submitMethod={(id: number, formData: IAvisMotiveFormData) => inspectionsService.updateAvisMotive(id, formData)}
                                                                           onSuccessSubmit={onSuccessSubmitForm}
                                                                           onCancel={onCancelForm}
                                                                           isOnCreationStep={false}/>}
                                            infos={<AvisMotiveInfosComponent avis={avis}/>}
                                        />
                                    )
                                }


                                <AsideInspectionsComponent type="fiche" inspection={inspection}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Suivi" icon="icon-suivi">
                            <div className="fiche-content -right-gutter border-footer">

                                {
                                    allInspections && allInspections.map((i: IInspection, index: number) =>
                                        <AccordionWithFormSwitchComponent
                                            ref={documentsAccordionRef}
                                            title={`Suivi ${i.annee}`}
                                            icon="icon-suivi"
                                            contentShowedOnInit={true}
                                            skipProtectionCheck={true}
                                            contentType="inspection"
                                            contentId={i.id}
                                            form={<InspectionDocumentsFormComponent inspection={i}
                                                                                    submitMethod={(formData: IInspectionDocumentsFormData) => inspectionsService.updateDocuments(i.id, formData)}
                                                                                    onSuccessSubmit={onSuccessSubmitForm}
                                                                                    onCancel={onCancelForm}/>}
                                            infos={<InspectionDocumentsComponent inspection={i}/>}
                                        />
                                    )
                                }
                                <AsideInspectionsComponent type="fiche" inspection={inspection}/>
                            </div>
                        </TabPanelComponent>


                        <TabPanelComponent label="Courriers" icon="icon-courrier">
                            <div className="fiche-content -right-gutter border-footer">
                                <div className="g-content">
                                    <InspectionCourrierFormForm inspection={inspection} allInspections={allInspections}
                                                                onSuccessSubmit={onSuccessSubmitCourrierForm}/>
                                </div>

                                <AsideInspectionsComponent type="fiche" inspection={inspection}/>
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
