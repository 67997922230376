import React, {SyntheticEvent, useEffect, useState} from "react";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "../../Services/Api.service";
import InputFieldComponent from "../Fields/Input.field.component";
import PasswordFieldComponent from "../Fields/Password.field.component";
import SelectFieldComponent, {IOption, IOptionsGroup} from "../Fields/Select.field.component";
import SelectMultipleFieldComponent from "../Fields/SelectMultiple.field.component";
import ErrorFormsMessagesComponent from "../Errors/ErrorFormsMessages.component";
import {Link} from "react-router-dom";
import CheckboxGroupField from "../Fields/CheckboxGroup.field.component";
import WysiwygFieldComponent from "../Fields/Wysiwyg.field.component";
import FileUploadField from "../Fields/FileUpload.field";
import RepeaterFieldComponent from "../Fields/Repeater.field.component";
import SelectBrutFieldComponent from "../Fields/SelectBrut.field.component";
import RadioButtonsField from "../Fields/RadioButtons.field.component";
import {IRadioFieldProps} from "../Fields/Radio.field.component";
import NumberExtendedFieldComponent from "../Fields/NumberExtended.field.component";
import FromToField from "../Fields/FromTo.field.component";
import CodePostalVille from "../Fields/CodePostalVille.field.component";
import LoaderComponent from "../Loading/Loader.component";
import CheckboxSingleFieldComponent from "../Fields/CheckboxSingle.field.component";
import DateFieldComponent from "../Fields/Date.field.component";
import LabelComponent from "../Fields/Label.component";
import {AdressesService} from "../../Services/Adresses.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {ITribunalJudiciaire} from "../../Models/TribunalJudiciaire.model";
import TextareaFieldComponent from "../Fields/Textearea.field.component";
import ReactHtmlParser from "react-html-parser";


export type FormColumn = {
    modificators?: string,
    elements: FormElement[],
};
export type IFormfieldCondition = {
    fieldName: string,
    oldFieldName?: string,
    value: string
}
export type FormElement = {
    type: 'text' | 'textarea' | 'hidden' | 'number' | 'numberExtended' | 'email' | 'tel' | 'url' | 'password' | 'select' | 'selectBrut' | 'selectMultiple' | 'link' | 'checkboxGroup' | 'date' | 'datetime-local' | 'datetime-local-with-step' | 'file' | 'wysiwyg' | 'repeater' | 'radio' | 'fromTo' | 'codePostalVille' | 'checkbox' | 'title' | 'year' | "buttonDeleteAction" | 'mention' | 'updateFieldButton' | 'informations' | 'previewPDF',
    fieldName?: string,
    key?: string,
    icon?: string,
    label?: string,
    labelModificators?: string,
    path?: string,
    linkTitle?: string,
    background?: string,
    placeholder?: string,
    placeholderKeyword?: string,
    required?: boolean,
    disabled?: boolean,
    modificators?: string,
    maxLength?: number,
    extensions?: string[],
    showImage?: boolean,
    showImageDeleteButton?: boolean,
    showFieldErrorDetail?: boolean,

    formColumns?: FormColumn[],

    options?: IOption[],
    optionsGroup?: IOptionsGroup[],

    radios?: IRadioFieldProps[],

    withTime?: boolean,
    timeInterval?: number,

    oldValue?: string,
    oldValues?: string[],
    oldValuesReadOnly?: string[],
    oldRepeatableValues?: FormComponentFormData,
    hideSearchOnMultiple?: boolean,
    hideCurrentValuesOnTop?: boolean,
    hideDeleteButton?: boolean,
    hideAddButton?: boolean,
    hideCheckAllGroupOptions?: boolean,
    hideEmptyOption?: boolean,
    shouldAddClassWithCountToParent?: boolean,
    hideEmpty?: boolean,
    condition?: IFormfieldCondition | IFormfieldCondition[],
    showMentions?: boolean,
    subMentions?: boolean,
    textMention?: string,

    action?: () => void,
    linkClick?: (formData?: FormComponentFormData) => void,
}
export type FormActions = {
    modificators?: string,
    onAction: (formData: FormComponentFormData, onActionEnded?: () => void) => void,
    label: string,
    icon?: string,
    hasLoading?: boolean,
    isActive?: boolean,
}

type FormCreatorComponentProps = {
    formColumns: FormColumn[],
    formActions?: FormActions[],
    onFormDataChange?: (formData: FormComponentFormData) => void,
    errorMessages?: IApiErrorMessage,
    errorFieldsMessages?: IApiFieldsErrorMessages,
    formDatas?: FormComponentFormData,
    modificators?: string,
    modificatorsActions?: string,
    showActionsBefore?: boolean,
    isStickyAction?: boolean,

    index?: number | string,
    repeaterName?: string,
}
export type FormComponentFormData = {
    [key: string]: any
}


export default function FormCreatorComponent(props: FormCreatorComponentProps) {
    const [formInitialized, setFormInitialized] = useState(false);
    const [formData, setFormData] = useState<FormComponentFormData>({});
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [loadingAction, setLoadingAction] = useState<FormActions>(null);

    useEffect(() => {
        setFormData(props.formDatas);
    }, [props.formDatas]);

    /**
     * On regénère les colonnes pour prendre en compte les conditions d'affichage au changement du formdata
     */
    useEffect(() => {
        const columns = getFormColumns(props.formColumns, formData);
        setFormColumns(columns);
    }, [formData, props.formColumns]);//TODO Vérifier si l'ajout de props.formColumns a un impact "négatif" sur les autres points


    useEffect(() => {
        // On ne va pas plus loin s'il n'y pas encore de colonnes ou si le form a déjà été généré
        if (!props.formColumns.length || formInitialized) return;

        const columns = getFormColumns(props.formColumns, getFormData(props.formColumns));
        setFormColumns(columns);

        setFormData(getFormData(columns));
        setFormInitialized(true);
    }, [props.formColumns]);

    /**
     * Génère les colonnes/champ du form en gérant les conditions d'affichage
     * @param {FormColumn[]} defaultFormColumns
     * @param {FormComponentFormData} currentData
     * @returns {FormColumn[]}
     */
    const getFormColumns = (defaultFormColumns: FormColumn[], currentData: FormComponentFormData) => {

        const newColumns: FormColumn[] = [];

        if (!defaultFormColumns || !currentData) return newColumns;

        defaultFormColumns.forEach((col: FormColumn) => {
            // Création d'une copie de la colonne courante
            const newCol = {...col};
            const elements: FormElement[] = [];
            // Filtre des élements en se basant sur leur condition d'affichage
            newCol.elements = newCol.elements.filter((el, index) => {
                return !el.condition || (el.condition && checkCondition(el.condition, currentData));
            });
            newColumns.push(newCol);
        });

        return newColumns;
    };

    /**
     * Vérifie si une condition d'affichage de champ est remplie
     * @param {IFormfieldCondition} condition
     * @param {FormComponentFormData} currentData
     * @returns {boolean}
     */
    const checkCondition = (condition: IFormfieldCondition | IFormfieldCondition[], currentData: FormComponentFormData) => {

        let conditions = Array.isArray(condition) ? condition : [condition];
        let passedConditionsNumber: number = 0;

        conditions.forEach((c) => {
            if (typeof currentData[c.fieldName] !== "undefined") {
                const result = c.value === currentData[c.fieldName];
                if (result) passedConditionsNumber++;
            }
        });
        return passedConditionsNumber === conditions.length;
    };

    /**
     * Permet de récupérer le formData selon les valeurs par défault
     *
     * @param {FormColumn[]} formColumns
     * @returns {FormComponentFormData}
     */
    const getFormData = (formColumns: FormColumn[]): FormComponentFormData => {
        // Maj du formdata avec les données par défaut
        const newFormData: FormComponentFormData = {...props.formDatas};

        formColumns.forEach((col: FormColumn) => {
            col.elements.forEach((el: FormElement) => {
                if (el.fieldName) {
                    if (el.type === "selectMultiple" || el.type === "checkboxGroup") {
                        newFormData[el.fieldName] = el.oldValues
                    } else if (el.type === "file") {
                        //newFormData[`${el.fieldName}`] = null;
                        newFormData[`${el.fieldName}_old`] = el.oldValue;
                    } else {
                        newFormData[el.fieldName] = el.oldValue;
                    }
                }
            })
        });

        return newFormData;
    };

    /**
     * Met à jour les données du formulaire
     * @param {string} fieldName
     * @param {string | string[] | FormComponentFormData} value
     * @param {string[]} keysToDelete
     */
    const updateFormData = (fieldName: string, value: string | string[] | FormComponentFormData, keysToDelete?: string[]): void => {
        let newFormData = {...formData};

        if (!Array.isArray(value) && typeof value === "object") {
            newFormData = {...newFormData, ...value};
        } else {
            newFormData[fieldName] = value;

            //Vérifier que la valeur du champ cpVille est bien formé (contient un -)
            if (fieldName === 'cpVille' && value && value.length && value.includes(' - ')) {
                if (newFormData['isForOffice'] && newFormData['isForOffice'] == "oui") {
                    const adressesService: AdressesService = new AdressesService();
                    const tribunalJudiciairePromise = adressesService.getTribunalForCodePostal({cpVille: value as string});
                    tribunalJudiciairePromise.then((result) => {
                        if (result && result.datas && result.datas.tribunal) {
                            const tribunal: ITribunalJudiciaire = result.datas.tribunal;
                            newFormData["tribunalJudiciaire"] = tribunal.identifiant;

                            setFormData(newFormData);
                            if (props.onFormDataChange) props.onFormDataChange(newFormData);
                        }
                    }, (error) => {
                        if (error.messages) {
                            notificationSystem.addNotification({
                                ...defaultNotificationConfig,
                                message: error.messages[0],
                                type: "danger"
                            });
                        }
                    });
                }
            }
        }

        if (keysToDelete) {
            keysToDelete.forEach((k) => {
                for (let [formDataKey, value] of Object.entries(newFormData)) {
                    if (formDataKey.indexOf(k) !== -1) {
                        delete (newFormData[formDataKey]);
                    }
                }
            });
        }

        setFormData(newFormData);
        if (props.onFormDataChange) props.onFormDataChange(newFormData);
    };

    const updateFormFileData = (fieldName: string, newFile: File, oldFile: string, deleteFile ?: boolean): void => {
        const newFormData = {...formData};

        newFormData[`${fieldName}_old`] = oldFile;
        newFormData[`${fieldName}_delete`] = deleteFile || false;
        newFormData[`${fieldName}`] = newFile;
        setFormData(newFormData);
        if (props.onFormDataChange) props.onFormDataChange(newFormData);
    };


    /**
     * Permet de récupérer les erreurs pour un champ
     *
     * @param {string} fieldName
     * @returns {string[]}
     */
    const getFieldsErrors = (fieldName: string): IApiErrorMessage => {
        let key: string = fieldName;
        let keyMore: string = fieldName;

        //Gestion des erreurs dans le cas d'un repeater
        if (props.index !== undefined && props.repeaterName) {
            const indexMore: number = parseInt(`${props.index}`) + 1;
            key = `${props.repeaterName}.${props.index}.${fieldName}`;
            keyMore = `${props.repeaterName}.${indexMore}.${fieldName}`;
        }

        if (!props.errorFieldsMessages) return null;


        return props.errorFieldsMessages[key]
    };


    /**
     * Retourne le html du champ/bouton
     * @param {FormElement} el
     * @returns {JSX.Element}
     */
    const getFormHtmlElement = (el: FormElement): JSX.Element => {
        switch (el.type) {
            case "text":
            case "hidden":
            case "email":
            case "url":
                return <InputFieldComponent
                    fieldName={el.fieldName}
                    triggerOnChangeOnOldValue={el.condition ? true : false}
                    key={el.key ? el.key : ""}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    disabled={el.disabled ?? false}
                    fieldType={el.type}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}/>;

            case "textarea":
                return <TextareaFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    disabled={el.disabled ?? false}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}/>;

            case "tel":
                return <NumberExtendedFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    fieldType="text"
                    maxLength={15}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}/>;

            case "date":
            case "datetime-local":
            case "datetime-local-with-step":
                return <DateFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    disabled={el.disabled ?? false}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    withTime={el.withTime}
                    timeInterval={el.timeInterval}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}/>;

            case "codePostalVille":
                return <CodePostalVille
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    modificators={el.modificators}
                    errors={getFieldsErrors("cpVille")}
                    fieldName={"cpVille"}
                    required={el.required}
                    onChange={(value => updateFormData(el.fieldName, value))}
                />;

            case "fromTo":
                return <FromToField
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    fieldType={"text"}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}/>;

            case "number":
                return <NumberExtendedFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    fieldType="text"
                    maxLength={el.maxLength}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}/>;

            case "password":
                return <PasswordFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label} labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    required={el.required}/>;

            case "select":
                return <SelectFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label}
                    labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    placeholderKeyword={el.placeholderKeyword}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    options={el.options}
                    optionsGroup={el.optionsGroup}
                    required={el.required}
                    hideEmptyOption={el.hideEmptyOption}
                    hideSearch={el.hideSearchOnMultiple}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                />;

            case "selectBrut":
                return <SelectBrutFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label}
                    labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    options={el.options}
                    optionsGroup={el.optionsGroup}
                    required={el.required}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                    disabled={el.disabled}
                />;

            case "selectMultiple":
                return <SelectMultipleFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label}
                    labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(values: string[]) => updateFormData(el.fieldName, values)}
                    placeholder={el.placeholder}
                    placeholderKeyword={el.placeholderKeyword}
                    oldValues={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValues ? el.oldValues : [])}
                    oldValuesReadOnly={el.oldValuesReadOnly ? el.oldValuesReadOnly : []}
                    options={el.options}
                    optionsGroup={el.optionsGroup}
                    hideSearch={el.hideSearchOnMultiple}
                    hideCurrentValuesOnTop={el.hideCurrentValuesOnTop}
                    hideCheckAllGroupOptions={el.hideCheckAllGroupOptions}
                    shouldAddClassWithCountToParent={el.shouldAddClassWithCountToParent || false}
                    required={el.required}
                    showFieldErrorDetail={el.showFieldErrorDetail ?? false}
                />;

            case "link":
                return <div className={`form-field`}>
                    {el.linkTitle && <LabelComponent fieldName={null} label={el.linkTitle}/>}
                    <Link onClick={() => {
                        el.linkClick != null && el.linkClick(formData)
                    }} to={el.path} className={`form-link ${el.modificators || ""}`}>{el.label}</Link>
                </div>

            case "informations":
                return <div className={`informations-block`}>
                    {el.label && <LabelComponent fieldName={null} label={el.label} modificators={el.modificators || ""}/>}
                    {el.oldValue && <p className="value">{ReactHtmlParser(el.oldValue)}</p>}
                </div>


            case "buttonDeleteAction":
                return <p>
                    <button className="g-button -delete-button icon-remove" onClick={el.action}/>
                    {el.label}
                </p>

            case "title":
                return <p
                    className="form-field-title">{formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}</p>

            case "mention":
                return <p className="mention">{el.label}</p>

            case "file":
                return <FileUploadField
                    label={el.label}
                    fieldName={el.fieldName}
                    errors={getFieldsErrors(el.fieldName)}
                    modificators={el.modificators}
                    background={el.background}
                    extensions={el.extensions || null}
                    showImage={el.showImage || false}
                    required={el.required}
                    showImageDeleteButton={el.showImageDeleteButton || false}
                    onChange={(newFile: File, oldFile: string, deleteFile?: boolean) => updateFormFileData(el.fieldName, newFile, oldFile, deleteFile)}
                    oldValue={el.oldValue || ""}
                    showMentions={el.showMentions != null ? el.showMentions : false}
                    textMention={el.textMention ? el.textMention : ""}
                />;

            case "wysiwyg":
                return <WysiwygFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label}
                    labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    placeholder={el.placeholder}
                    oldValue={(el.oldValue ? el.oldValue : "")}
                    required={el.required}
                    showMentions={el.showMentions != null ? el.showMentions : true}
                    subMentions={el.subMentions}
                />;

            case "checkboxGroup":
                return <CheckboxGroupField
                    icon={el.icon || ''}
                    label={el.label}
                    id={el.fieldName}
                    options={el.options}
                    onChange={(values: string[]) => updateFormData(el.fieldName, values)}
                />;

            case "checkbox":
                return <CheckboxSingleFieldComponent
                    fieldName={el.fieldName}
                    modificators={el.modificators}
                    label={el.label}
                    labelModificators={el.labelModificators}
                    errors={getFieldsErrors(el.fieldName)}
                    onChange={(value: string) => updateFormData(el.fieldName, value)}
                    oldValue={formData && formData[el.fieldName] ? formData[el.fieldName] : (el.oldValue ? el.oldValue : "")}
                    required={el.required}
                />;


            case "repeater":
                return <RepeaterFieldComponent
                    errorFieldsMessages={props.errorFieldsMessages || null}
                    fieldName={el.fieldName}
                    label={el.label}
                    labelModificators={el.labelModificators}
                    onChange={(values, keysToDelete) => updateFormData(el.fieldName, values, keysToDelete)}
                    formColumns={el.formColumns}
                    hideDeleteButton={el.hideDeleteButton}
                    hideAddButton={el.hideAddButton}
                    required={el.required}
                    isOrigineAvocat={formData && formData.origine && formData.origine === 'avocat'}
                    oldValues={el.oldRepeatableValues as FormComponentFormData[]}/>;

            case "radio":
                return <RadioButtonsField
                    oldValue={formData[el.fieldName] || el.oldValue || ''}
                    modificators={el.modificators}
                    onChange={(value) => updateFormData(el.fieldName, value)}
                    options={el.options}
                    fieldName={el.fieldName}
                    label={el.label}
                    disabled={el.disabled}
                    errors={getFieldsErrors(el.fieldName)}
                    required={el.required}/>;

            case "updateFieldButton":
                return <div className={`form-field form-field-update-button ${el.modificators ?? ''}`}>
                    <label className="form-field-label">{el.label}</label>
                    <div className="select-wrapper" onClick={el.action}>
                        <div className="form-field-input">
                            {el.oldValue !== null ? el.oldValue : ''}
                        </div>
                        <span className="form-field-aside -with-text -is-absolute">
                            <i className="icon icon-edit"/>
                            {el.labelModificators ? el.labelModificators : 'Modifier'}
                        </span>
                    </div>
                </div>

            case "previewPDF":
                //Note : Comportement un peu particulier :
                //Voir app/src/Components/Forms/Correspondances/Courriers/CorrespondanceCourrierForm.component.tsx
                //Voir server/app/Http/Controllers/API/CorrespondancesController.php
                return <>
                    {(el.oldValue != null) &&
                        <div>
                            <header className="accordion-header -active">
                                <div className="left">
                                    <header className="informations-header">
                                        <i className={`icon ${el.icon != null ? el.icon : 'icon-voir'}`}></i>
                                        <span className="title">
                                            {el.label != null ? el.label : 'Prévisualisation'}
                                        </span>
                                    </header>
                                </div>
                                {el.action != null &&
                                    <div className="right">
                                        <a className="see-more-link -small" onClick={el.action}>
                                            <span className="text">Fermer</span>
                                            <i className="icon icon-fermer"></i>
                                        </a>
                                    </div>
                                }
                            </header>
                            <div className="informations-wrapper">
                                <embed src={`${el.oldValue}#toolbar=0`} width="100%" height="600px" type="application/pdf" />
                            </div>
                        </div>
                    }
                </>
        }


        return null;
    };


    /**
     * Prend en charge le soumission du formulaire
     * @param {React.SyntheticEvent} e
     * @param {FormActions} action
     */
    const onAction = (e: SyntheticEvent, action: FormActions): void => {
        e.preventDefault();
        if (action.isActive) return;

        if (action.hasLoading) {
            setLoadingAction(action);
        }
        action.isActive = true;

        if (action.onAction) {
            action.onAction(formData, () => {
                if (action.hasLoading) {
                    setLoadingAction(null);
                }
                action.isActive = false;
            });
        }

        setTimeout(() => {
            action.isActive = false;
        }, 700);
    };

    const isActionLoadding = (action: FormActions) => {
        return action === loadingAction;
    }


    const showFormActions = (isBefore?: boolean, isSticky?: boolean) => {
        if (!props.formActions || !props.formActions.length) return null;

        return (
            <div
                className={`form-actions ${props.modificatorsActions || ""} ${isBefore ? '-before' : ''} ${isSticky ? '-sticky' : ''}`}>
                {
                    props.formActions.map((action: FormActions, index: number) =>
                        <button
                            className={`g-button ${action.modificators || ""} ${action.icon ? '-with-icon' : ''} ${isActionLoadding(action) ? '-loading' : ''}`}
                            key={index}
                            onClick={(e) => onAction(e, action)}>
                            {
                                !isActionLoadding(action) &&
                                <>
                                    {
                                        action.icon &&
                                        <i className={`picto ${action.icon}`}/>
                                    }
                                    {
                                        action.label
                                    }
                                </>
                            }
                            {
                                isActionLoadding(action) &&
                                <LoaderComponent/>
                            }
                        </button>
                    )
                }
            </div>
        )
    }


    if (!formInitialized) return null;

    return (
        <div className={`form-wrapper ${props.modificators || ""}`}>
            <ErrorFormsMessagesComponent messages={props.errorMessages}/>

            {
                props.showActionsBefore &&
                showFormActions(true)
            }

            <div className="columns">
                {
                    formColumns.map((col: FormColumn, indexCol: number) =>
                        <div className={`column ${col.modificators || ""}`} key={indexCol}>
                            {
                                col.elements.map((el, index) =>
                                    <React.Fragment key={index}>
                                        {getFormHtmlElement(el)}
                                    </React.Fragment>
                                )
                            }
                        </div>
                    )
                }
            </div>

            {
                showFormActions(false, props.isStickyAction)
            }

        </div>
    );
}
